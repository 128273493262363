import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown } from "../../global";
import { CALL_NOTIFY } from "../../global/store/action";
import { api } from "../../api";
import CONST from "../../locale.json";
import './CreateNotification.scss';
const eventType = { "onExecutionCompletion": "On execution completion" };
const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}
const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    borderRadius: "0px",
    boxShadow: "none",
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    fontWeight: 500,
};
const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};
export const CreateNotification = () => {

    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [eventID, setEventID] = useState<any>({});
    const [eventDictionary, setEventDictionary] = useState<any>([])
    const [eventExists, setEventExists] = useState<boolean>(false)
    const [templateId, setTemplateId] = useState<string>("")
    const dispatch = useDispatch();

    const fetchEvent = async (e?: any) => {

        let eventID: string;
        // fetch all events
        if (!e) {
            eventID = e
        }
        // fetch particular event template 
        else {
            eventID = e.id
        }
        // set events list in dropdown 
        if (!e) {

            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHNOTIFICATIONCONFIG, { params: `/event/${eventID}` }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

            var eventList: any = []
            for (var itr of resp.data.data) {
                let evtName: string = "";
                Object.entries(eventType).forEach((e: any) => {
                    if (itr.name === e[0]) {
                        evtName = e[1]
                    }
                })
                eventList.push({ id: itr.id, value: itr.name, label: evtName })
            }
            setEventDictionary(eventList)
        }
        else {
            // set event value in dropdown
            setEventID({ id: e.id, value: e.value })

            // fetch event template by event id
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHNOTIFICATIONCONFIG, { params: `/template/event/${eventID}` }, dispatch)
            if (resp.err) {
                setSubject("")
                setBody("")
                setTemplateId("")
                setEventExists(false)
                return
            }
            // if event template already exists then set template values
            setSubject(resp.data.data.subject)
            setBody(resp.data.data.body)
            setTemplateId(resp.data.data.id)
            setEventExists(true)
        }
    }

    const creatNotification = async () => {
        let valid = validate();
        if (valid === 'valid') {

            let template = {
                eventId: eventID.id,
                subject: subject,
                body: body
            }

            // if event already exists then call update api
            if (eventExists) {
                const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATENOTIFICATIONCONFIG, { params: `template/event/${templateId}`, body: template }, dispatch)
                if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
                dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Notification updated successfully" } });
            }
            // else call create template api
            else {
                const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATENOTIFICATIONCONFIG, { params: "template/event/", body: template }, dispatch)
                if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
                dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Notification created successfully" } });
            }
        }
        else {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: valid } });
        }
    }

    const validate = () => {

        if (subject === "") {
            return "Please enter the subject";
        }
        else if (body.length < 50) {
            return "Please enter the body minimum length 50";
        }
        else if (eventID.id === "") {
            return "Please select the event";
        }
        else {
            return "valid";
        }
    }
    useEffect(() => {
        fetchEvent()
    }, [])

    return (
        <div className="create-notification-container dds-link">
            <h3 className="dds-h5-lm">Create notification</h3>
            <div className="create-notification-div ">
                <label>Event ID</label>
                <Dropdown placeholderStyle={placeholderStyle} controlStyle={DropdownStyle} dropdownIndicatorStyle={DropdownIndicatorStyle} style={{ width: "100%" }}
                    onchange={(e: any) => fetchEvent(e)} placeholder="Select" showValue options={eventDictionary} />
            </div>
            <div className="create-notification-div">
                <label>Subject</label>
                <input className="admin-textbox" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
            </div>
            <div className="create-notification-div">
                <label>Body</label>
                <textarea name="body"
                    onChange={(e) => setBody(e.target.value)}
                    value={body} />
                {/* <input className="admin-textbox" type="text" value={body} onChange={(e) => setBody(e.target.value)} /> */}
            </div>
            <div className="create-notification-btn">
                <Button title="Save" style={{ width: "18%" }} className="--savebtn" onClick={creatNotification} disabled={false} />
            </div>
        </div>
    )
}