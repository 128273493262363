import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACTIVE_TAB } from "../store/action";
import { RootState } from "../../reducer";
import './TabbedNavigator.scss';
import { CreateUser } from './CreateUser';
import BulkUserCreation from "./BulkUserCreation";


const tabItems: Record<string, string> = {
    "ADD_MANUALLY": "Add manually",
    "BULK_CREATION": "Bulk user creation"
};

const TabbedNavigator: FC = () => {
    const activeTab = useSelector((state: RootState) => state.adminReducer.activePage.tab)
    const dispatch = useDispatch();

    return (
        <div className="--tabbed-nav-bar">
            <div className="--tabbed-nav">
                {Object.keys(tabItems).map(t => (
                    <span
                        key={t}
                        className={activeTab === t ? "active-nav-tab nav-tab-item" : "nav-tab-item"}
                        onClick={() => dispatch({ type: SET_ACTIVE_TAB, payload: { tab: t } })}
                    >
                        {tabItems[t]}
                    </span>
                ))}
            </div>

        </div>
    );
};

export default TabbedNavigator;
