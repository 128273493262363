import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CONST from "../../locale.json";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action"
import { api } from "../../api";
import { RootState } from "../../reducer";
import { TableView } from './TableView';
import { PopUpModel } from "../../global/components/PopUpModel";
const header =
    [{
        "id": "id",
        "userName": "Username",
        "type": "Type",
        "refreshTime": "Last access",
        "loginTime": "Login time"
    }];
const months = CONST.months;
export const UserSession = () => {

    const dispatch = useDispatch();
    const refreshtreeviewer = useSelector((state: RootState) => state.globalReducer.refreshTreeData)
    const [clientStatus, setClientStatus] = useState<{ username: string, type: string, start: string, lastAccess: string }[]>();
    const [logoutUser, setLogoutUser] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<string>("");

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'USERSESSION' })
        dispatch({ type: SELECTEDPARENT, payload: 'Status and session' })
    }, [])

    const processDate = (d: string): string => {
        const date = new Date(TimeFormat(d))
        const dt = [date.getDate(), date.getMonth(), date.getFullYear()];
        return `${("0" + dt[0]).slice(-2)} ${months[+dt[1]]} ${dt[2]}`;
    };

    const processTime = (t: string): string => {
        const time = new Date(TimeFormat(t))
        const nt = [time.getHours(), time.getMinutes()];
        return `${("0" + ((+nt[0] % 12) || 12)).slice(-2)}:${("0" + nt[1]).slice(-2)} ${(+nt[0] < 12) ? "AM" : "PM"}`;
    };

    const TimeFormat = (time: any) => {
        var dateUTC = new Date(time);
        var dateIST = new Date(dateUTC.getTime());
        //date shifting for IST timezone (+5 hours and 30 minutes)
        dateIST.setHours(dateIST.getHours());
        dateIST.setMinutes(dateIST.getMinutes());
        return dateIST;
    }
    useEffect(() => {
        getStatus();
    }, [refreshtreeviewer])

    const getStatus = async () => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.GETUSERSESSION, {}, dispatch)
        if (resp.err){ 
            if(resp.err.includes("ECONNREFUSED")){
                return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Database is down" } });
            }
            return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        }
        resp.data.forEach((item: any) => {
            for (let key in item) {
                if (!(Object.keys(header[0]).includes(key))) {
                    delete item[key]
                }
            }
        });
        resp.data.forEach((item: any) => {
            item.start = (processDate(item.refreshTime) + ", " + processTime(item.refreshTime)).toString()
            delete item.refreshTime;
            item.lastAccess = (processDate(item.loginTime) + ", " + processTime(item.loginTime)).toString()
            delete item.loginTime;
        });
        setClientStatus(resp.data);
    }

    const removeClientSession = async (sessionID: string) => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.REMOVEUSERSESSION, { params: sessionID }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "User logged out successfully" } });
        setLogoutUser(false);
        getStatus()
    }

    const Logout = (e: any) => {
        setLogoutUser(true);
        setSelectedUser(`${e.type}/${e.userName}`)
    }

    return (
        <>
            <TableView Title={"User session"} tableData={clientStatus} showSLNo={false} header={Object.values(header[0])} onClickLogout={Logout} filter="userName" searchPlaceHolder="Search user" />
            {logoutUser && <PopUpModel Title="Log out" onClickClose={() => setLogoutUser(false)} onSaveClick={() => { removeClientSession(selectedUser) }} screenBlock closeTitle="Cancel" saveTitle="Log out">
                <div className="body-content dds-body">Are you sure you want to log the user out of the session?</div>
            </PopUpModel>}
        </>
    )

}