import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CONST from "../../locale.json";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT,SELECTEDLEAF } from "../store/action";
import { api } from "../../api";
import { TableForApi } from './TableForApi';


const months = CONST.months;
export const ManageApiKey = () => {
    const [apiKeyList, setApiKeyList] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'MANAGEAPIKEY' })
        dispatch({type:SELECTEDPARENT, payload : 'Third-party integration'})
    }, [])

    const processApiKey = (k: string) => {
        const invisible = "****";
        return `${k.slice(0, 4)}${invisible}`
    }

    const processDate = (d: string): string => {
        const date = new Date(TimeFormat(d))
        const dt = [date.getDate(), date.getMonth(), date.getFullYear()];
        let [hours,minutes,seconds] = [date.getHours(),date.getMinutes(),date.getSeconds()]
        let ampm =hours>=12?"PM":"AM";
        hours = hours % 12 || 12;
        const time = [hours,minutes,seconds].map((t)=>(t<10?`0${t}`:t)).join(":");

        const formattedDate=`${dt[0].toString().padStart(2,'0')} ${months[+dt[1]]} ${dt[2]}`;

        return `${formattedDate}, ${time} ${ampm}`
        // return `${("0" + dt[0]).slice(-2)} ${months[+dt[1]]} ${dt[2]}`;
    };
    const TimeFormat = (time: any) => {
        var dateUTC = new Date(time);
        var dateIST = new Date(dateUTC.getTime());
        return dateIST;
    }

    const fetchApiKey = async () => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHAPIKEY, {}, dispatch)
        if (resp.err){ 
            if(resp.err.includes("ECONNREFUSED")){
                return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Database is down" } });
            }
            return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        }
        resp.data.forEach((item: any) => {
            item.expiry = (processDate(item.expiry)).toString()
            item.createdOn = (processDate(item.createdOn)).toString()
            item.apiKey = processApiKey(item.apiKey)
        })

        resp.data.sort(function (a: any, b: any) { return (a["keyName"].toLocaleLowerCase() > b["keyName"].toLocaleLowerCase()) ? 1 : ((b["keyName"].toLocaleLowerCase() > a["keyName"].toLocaleLowerCase()) ? -1 : 0); });
        setApiKeyList(resp.data)
    }
    useEffect(() => {
        fetchApiKey();
    }, [])

    const editApiKey = async (status: string, id: string) => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.REVOKEAPIKEY, { params: id, query: { status } }, dispatch);
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        fetchApiKey();
        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Successfully revoked API key" } });
    }
    return (
        <>
            <TableForApi Title={"Manage API key"} tableData={apiKeyList} onClickEdit={(status: string, id: string) => editApiKey(status, id)} filter="keyName" />
        </>
    )

}