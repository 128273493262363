import { CSSProperties, FC, useEffect, useState } from 'react';
import Select, { ActionMeta, GroupBase, MultiValue, SingleValue, StylesConfig } from 'react-select';
import CONST from "../../locale.json";
import './Dropdown.scss';

interface OptionsInterface {
    label: string;
    options?: { label: string; value: string; }[];
    value?: string;
    disabled?: boolean
}

interface DropdownInterface {
    style?: CSSProperties,
    className?: string,
    isSearchable?: boolean,
    options: OptionsInterface[],
    onchange: (newValue: SingleValue<OptionsInterface> | MultiValue<OptionsInterface>, actionMeta: ActionMeta<OptionsInterface>) => void,
    placeholder: string,
    showValue?: boolean,
    borderHide?: boolean,
    defaultValue?: OptionsInterface,
    disabled?: boolean,
    controlStyle?: CSSProperties,
    dropdownIndicatorStyle?: CSSProperties,
    placeholderStyle?: CSSProperties,
    containerStyle?: CSSProperties,
    reset?: boolean
}

const cnDefault = {};
const diDefault = {};
const phDefault = {};
const coDefault = {};

export const Dropdown: FC<DropdownInterface> = ({ style = {}, className = '', controlStyle = cnDefault, dropdownIndicatorStyle = diDefault, placeholderStyle = phDefault, containerStyle = coDefault, options, showValue = false, defaultValue = { label: "", value: "" }, placeholder, onchange, isSearchable = false, borderHide = false, disabled = false, reset }) => {
    const [selectedValue, setSelectedValue] = useState<OptionsInterface>({ label: "", value: "" });
    const [custstyles, setCuststyles] = useState<StylesConfig<OptionsInterface, boolean, GroupBase<OptionsInterface>>>({});
    useEffect(() => {
        setSelectedValue({ label: "", value: "" })
    }, [reset])

    useEffect(() => {
        if (defaultValue.value) {
            setSelectedValue(defaultValue)
        }
    }, [defaultValue]);
    useEffect(() => {
        setCuststyles({
            indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
            dropdownIndicator: (styles) => ({
                ...styles, color: CONST.COLOR.BASECOLOR, ":hover": {
                    color: CONST.COLOR.BASECOLOR
                },
                ...dropdownIndicatorStyle,
            }),
            group: (styles) => ({
                ...styles,
                borderBottom: `1px solid ${CONST.COLOR.BORDERGREY}`,
                ":last-child": {
                    borderBottom: "None"
                }
            }),
            control: (styles, { isFocused }) => ({
                ...styles,
                border: borderHide ? `none` : `2px solid ${CONST.COLOR.BASECOLOR}`,
                borderRadius: "0px",
                boxShadow: 'none',
                ":hover": {
                    border: borderHide ? `none` : `2px solid ${CONST.COLOR.BASECOLOR}`
                }, ':active': {
                    border: borderHide ? `none` : `2px solid ${CONST.COLOR.BASECOLOR}`
                },
                zIndex: 1,
                ...controlStyle
            }),
            menu: base => ({
                ...base,
                borderRadius: "0px",
                zIndex: 100
            }),
            singleValue: (styles) => ({ ...styles, color: CONST.COLOR.BASECOLOR, ...placeholderStyle }),
            valueContainer: (styles) => ({ ...styles, ...containerStyle }),
            option:
                (styles, { isFocused, isSelected }) => ({
                    ...styles,
                    fontWeight: 500,
                    color: isSelected ? "black" : "black",
                    background: isSelected ? CONST.COLOR.OPTIONSELECTED : isFocused ? CONST.COLOR.OPTIONBACKGROUND : CONST.COLOR.WHITECOLOR,
                    zIndex: 1,
                    ":hover": {
                        background: isSelected ?
                            CONST.COLOR.OPTIONSELECTED :
                            isFocused ? CONST.COLOR.OPTIONBACKGROUND : CONST.COLOR.WHITECOLOR
                    },
                }),
            placeholder: (styles) => ({ ...styles, color: CONST.COLOR.BASECOLOR, ...placeholderStyle })
        });
    }, [controlStyle, dropdownIndicatorStyle, placeholderStyle, containerStyle, borderHide])
    const onValuechange = (newValue: any, actionMeta: any) => {
        setSelectedValue(newValue)
        onchange(newValue, actionMeta)
    }

    return (
        <div className={`dropdown-main dds-body-semibold noselect ${className}`} style={style} >
            <Select
                value={showValue ? selectedValue : { value: placeholder, label: placeholder }}
                placeholder={placeholder}
                styles={custstyles}
                isSearchable={isSearchable}
                onChange={onValuechange}
                options={options}
                isDisabled={disabled}
            />
        </div >
    );
}