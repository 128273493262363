import { FC } from "react";
import { ScreenBlock, Button, CloseIconSvg } from "../";
import "./PopUpModel.scss";
import { title } from "process";

interface PopUpModelInterface {
    Title: string,
    onClickClose: () => void,
    onSaveClick?: () => void,
    disabledSave?: boolean,
    screenBlock?: boolean,
    saveTitle?: string,
    closeTitle?: string,
    Footer?: FC,
    classStyle?: any
}
// Function to determine the title style
const getTitleStyle = (Title: string) => {
    if (Title === "Warning") {
        return { color: '#D32F2F' }; // Red color for warning
    }
    return {}; // Default style (no change)
};

export const PopUpModel: FC<PopUpModelInterface> = (props) => (
    <>
        {
            props.screenBlock === false ?
                < ModelComp {...props} /> :
                <ScreenBlock>
                    < ModelComp {...props} />
                </ScreenBlock >
        }
    </>
)

const ModelComp: FC<PopUpModelInterface> = ({ children, Title, onClickClose, onSaveClick = () => { }, disabledSave = false, saveTitle = "Save", closeTitle = "Close", Footer = null, classStyle }) => {
    const titleStyle = getTitleStyle(Title);
    return (
        <div className="popup-main" >
            <div className="--header">
                <div className="--title-head dds-h5" style={{ width: '318px', height: '24px', marginLeft: '7px', ...titleStyle }}>{Title} </div>
                <img src={CloseIconSvg} onClick={onClickClose} alt="close"></img>
            </div>

            <div className="--contentbody">
                {children}
            </div>
            {Footer ?
                <Footer /> :
                <div className="--footer">
                    <Button disabled={disabledSave} className={'--testsave --popupbtn ' + classStyle} title={saveTitle} onClick={() => onSaveClick()}></Button>
                    <Button className="--testcancel --popupbtn" title={closeTitle} onClick={onClickClose}></Button>
                </div>
            }
        </div >
    )
}