import { FC, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CloseIconSvg, CloseWhiteIconSvg, ErrorMsgIcon, SuccessIconSvg, WarningIcoSvg, InfoWhiteIconSvg } from ".."
import { RootState } from "../../reducer"
import { CLOSE_NOTIFY } from "../store/action"
import "./Notify.scss"

interface NotifyInterface {
}

var TimeoutEventId: any = [];
export const Notify: FC<NotifyInterface> = () => {
    const notify = useSelector((state: RootState) => state.globalReducer.notify)
    const dispatch = useDispatch()

    const color: any = useMemo(() => ({
        "SUCCESS": {
            svg: SuccessIconSvg,
            close: CloseWhiteIconSvg
        },
        "ERROR": {
            svg: ErrorMsgIcon,
            close: CloseWhiteIconSvg
        },
        "WARNING": {
            svg: WarningIcoSvg,
            close: CloseIconSvg
        },
        "INFO": {
            svg: InfoWhiteIconSvg,
            close: CloseWhiteIconSvg
        }
    }), []);

    const onClickClose = (id: string) => {
        clearTimeout(TimeoutEventId[notify.findIndex((item: any) => item.id === id)])
        const toast = document.querySelector(`.toast-container .--toast[data-id="${id}"]`);
        if (toast) {
            toast.classList.add("hide");
        }
        //below timeout can be removed if find any regularities this is to get close animation done
        setTimeout(() => {
            dispatch({ type: CLOSE_NOTIFY, payload: id })
        }, 200);
    }

    useEffect(() => {
        notify.forEach((obj: any) => {
            if (obj !== "INFO") {
                const timeoutId = setTimeout(() => onClickClose(obj.id), 3900);
                TimeoutEventId.push(timeoutId)
            }
        });
    }, [notify]);

    return (
        <div className="toast-container noselect" data-position="bottom-center">
            {
                notify?.map((notification) => (
                    <div key={notification.id} className={`--toast --${notification.type.toLowerCase()} show`} data-id={notification.id} >
                        <div className="--spanblock">
                            <img className="--type" src={color[notification?.type]?.svg} alt={color[notification?.type]}></img>
                            <span className="--msg">{notification.msg}</span>
                        </div>
                        <img className="--close" onClick={() => onClickClose(notification.id)} src={color[notification.type].close} alt="close"></img>
                    </div>
                ))
            }
        </div>
    )
}