import { Provider } from "react-redux";
import { store, persistor } from "./reducer";
import { PersistGate } from 'redux-persist/integration/react'
import {RouterProvider} from 'react-router-dom'
import router from "./router"


function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <RouterProvider router={router}/>
            </PersistGate>
        </Provider >
    );
}

export default App;
