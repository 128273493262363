import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults } from 'axios';
import { Dispatch } from "react"
import { AnyAction } from "redux"
import CONST from './locale.json'
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG, LOGIN } from "./global/store/action";
import { SELECTEDADMINSCREEN } from './admin/store/action';
import { response } from 'express';

type RequestMethod = { module: string, version: string, method: string };

class APIRequest {
    private instance: AxiosInstance;
    private headers: HeadersDefaults["common"] = { "Accept": "application/json", "Content-Type": "application/json; charset=utf-8" };

    constructor() {
        this.instance = axios.create({
            headers: this.headers,
            withCredentials: true,
            // httpsAgent: new https.Agent({
            //     rejectUnauthorized: false
            // })
        });
    }

    addHeader(header: object) {
        Object.assign(this.headers, header);
    }

    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.instance.request(config);
    }

    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.get<T, R>(url, config);
    }

    post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.post<T, R>(url, data, config);
    }

    put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.put<T, R>(url, data, config);
    }

    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.delete<T, R>(url, config);
    }
}

const axiosClient = new APIRequest();

async function makeRequest(req: Record<string, any>) {
    try {
        if (localStorage.getItem("accessToken")) {
            req.headers = {
                ...req.headers,
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
        }
        const res = await axiosClient.request(req);
        return res
    } catch (err: any) {
        if (err.response.status === 401 || (err.response.status === 422 && err.response.data.message === "HTTP 401 Unauthorized")) {
            let refreshReq: Record<string, any> = {};
            refreshReq.url = `/${CONST.CHANNEL.ADMIN}/api/${CONST.METHOD.ADMIN.TOKEN.version}/${CONST.METHOD.ADMIN.TOKEN.module}/refreshaccesstoken/admin`;
            refreshReq.method = CONST.METHOD.ADMIN.TOKEN.method;
            refreshReq.data = { refreshToken: localStorage.getItem("refreshToken") };
            let refreshResp = await axiosClient.request(refreshReq);
            localStorage.setItem("accessToken", refreshResp?.data?.data?.accessToken);
            if (refreshResp?.data?.data?.accessToken) {
                req.headers = {
                    ...req.headers,
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                };
            }
            let res = await axiosClient.request(req);
            return res
        } else throw err;
    }
}

/**
 * generic api to call channels on IpcBridge
 *
 * @param channel channel name
 * @param method Object conataining module name, request type (GET/POST/PUT/DELETE) and api version
 * @param payload  payload for the api
 * @returns promise
 */

export const api = async (channel: string, method: RequestMethod, payload: any, blockScreenDispatch: Dispatch<AnyAction> | undefined = undefined, blockScreenMsg: string = "Loading") => {
    const req: Record<string, any> = {};
    let res;
    if (blockScreenDispatch) blockScreenDispatch({ type: SHOWSCREENBLOCKMSG, payload: blockScreenMsg + "..." })
    try {

        // var res = await window.IpcBridge.send(channel, { method: method, params: params })
        req.url = `/${channel}/api/${method.version}/${method.module}`;

        req.method = method.method;

        let query: any = payload.query
        let params: string = payload.params
        let body: any = payload.body
        if (query) {
            let value = new URLSearchParams();
            for (let key in query) {
                value.append(key, query[key]);
            }
            req.params = value;
        }

        if (params) {
            req.url = `${req.url}/${params}`
        }

        if (body) {
            req.data = body
        }
        res = await makeRequest(req);
        if (blockScreenDispatch) blockScreenDispatch({ type: SHOWSCREENBLOCKMSG, payload: "" })
        return res.data;
    }
    catch (err: any) {
        if (err.response.status === 401 && err.response.data.message === "SESSIONEXPIRED") {
            if (blockScreenDispatch) {
                blockScreenDispatch({ type: SELECTEDADMINSCREEN, payload: "ADMINHOME" })
                blockScreenDispatch({ type: LOGIN, payload: false })
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                return { err: "Session expired", msg: "Session expired", statusCode: err.response?.status, errorCode: err.response?.data?.message };
            }
        }
        if (blockScreenDispatch) blockScreenDispatch({ type: SHOWSCREENBLOCKMSG, payload: "" })
        return { err: err.response?.data?.message || err.message, error: err, message: method + " API failed" }
    }

}