import * as act from './action'
interface StateInterface {
    activePage: { tab: "ADD_MANUALLY" | "BULK_CREATION" },
    selectedAdminScreen: string,
    selectedRole: string,
    selectedUser: Record<string, any>,
    selectedProject: string,
    selectedLeaf: string,
    accessToken: string,
    userName: string,
    selectedParent: string,
    adminRoleID: string
}
const initialState: StateInterface = {
    activePage: { tab: "ADD_MANUALLY" },
    selectedAdminScreen: "ADMINHOME",
    selectedRole: "",
    selectedUser: {},
    selectedProject: "",
    selectedLeaf: "",
    accessToken: "",
    userName: "",
    selectedParent: "",
    adminRoleID: ""
}


type actionType = {
    type: string,
    payload: any
}
export default function adminReducer(state = initialState, action: actionType) {
    switch (action.type) {
        case act.SET_ACTIVE_TAB:
            if (!action.payload.tab) action.payload.tab = state.activePage.tab;

            return {
                ...state,
                activePage: {

                    ...state.activePage,
                    tab: action.payload.tab || state.activePage.tab
                }
            }
        case act.SELECTEDADMINSCREEN:
            return { ...state, selectedAdminScreen: action.payload }
        case act.SELECTEDROLE:
            return { ...state, selectedRole: action.payload }
        case act.SELECTEDUSER:
            if (!action.payload.tab) action.payload.tab = state.activePage.tab;
            return { ...state, selectedUser: action.payload }
        case act.SELECTEDPROJECT:
            return { ...state, selectedProject: action.payload }
        case act.SELECTEDLEAF:
            return { ...state, selectedLeaf: action.payload }
        case act.ACCESSTOKEN:
            return { ...state, accessToken: action.payload }
        case act.USERNAME:
            return { ...state, userName: action.payload }
        case act.SELECTEDPARENT:
            return { ...state, selectedParent: action.payload }
        case act.ADMINROLEID:
            return { ...state, adminRoleID: action.payload }
        default:
            return state


    }
}