import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { PopUpModel } from "./PopUpModel";

interface WarningScreenMsgInterface {
    onClose: () => void,
    onContinue: () => void
}

export const WarningScreenMsg: FC<WarningScreenMsgInterface> = ({ onClose, onContinue }) => {
    const [msg, setMsg] = useState<string | false>(false)






    return (
        msg ?
            <>
                <PopUpModel Title="Warning !!!"
                    onClickClose={onClose}
                    closeTitle={"Cancel"}
                    onSaveClick={onContinue}
                    saveTitle={"Continue"}
                >
                    <div className="--formItem dds-body">
                        <div>{msg}</div>
                    </div>
                </PopUpModel>
            </>
            : null
    )
}