import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../api";
import { Button, Grid, GridItem, TagGreyIconSvg } from "../../global";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT,SELECTEDLEAF } from "../store/action";
import CONST from "../../locale.json";
import "./CreateLabel.scss";

type TagInterface = { name: string; color: string; background: string; createdOn?: Date; modifiedOn?: Date; }
type TemplateInterface = { color: string; background: string; }
type CreateLabelInterface = {
    editDone?: () => void;
    edit?: TagInterface;
}

export const CreateLabel: React.FC<CreateLabelInterface> = ({ edit, editDone }) => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [templates, setTemplates] = useState<TemplateInterface[]>([]);
    const [tag, setTag] = useState<TagInterface>({ name: "", color: "", background: "" });
    const [valid, setValid] = useState<boolean>(true);
    const [isNameEnabled, setIsNameEnabled] = useState(false)
    const [iscolorEnabled, setIscolorEnabled] = useState(false)
 
    const isSameName = edit && tag.name.trim() === edit.name.trim();
    const isColorsame = edit && tag.background === edit.background;

    const namevalid = tag.name.trim() !== '' && tag.background !== '' && !isSameName && valid; 
    const colorvalid = tag.name !== '' && tag.background !== '' && !isColorsame && valid;

    // const isValid = () => (tag.name && tag.color && valid &&(isNameEnabled||iscolorEnabled));

    const isValid = () => {
        if(namevalid){
            return isNameEnabled ;
        }
        else if(colorvalid){
            return iscolorEnabled;
        }
    };

    useEffect(() => {
        dispatch({type:SELECTEDPARENT, payload : 'Label Management'})
        dispatch({ type: SELECTEDLEAF, payload: 'CREATELABEL' })
    }, [])

    const getTemplates = useCallback(async () => {
        const templates = await api(CONST.CHANNEL.TCM, CONST.METHOD.TCM.FETCHLABELTEMPLATES, {}, dispatch);
        if (templates.err) {
            setLoaded(true);
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: templates.err } });
            return setTemplates([]);
        }
        const tags = await api(CONST.CHANNEL.TCM, CONST.METHOD.TCM.FETCHLABEL, {}, dispatch);
        setLoaded(true);
        if (tags.err) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: tags.msg } });
            return setTemplates([]);
        }
        const usedColors = (tags.data as TagInterface[]).map(t => t.background);
        let availableCols = (templates.data as TemplateInterface[]).filter(t => !usedColors.includes(t.background));
        if (edit) {
            dispatch({ type: SELECTEDLEAF, payload: 'EDITLABEL' })
            const nTag = { name: edit.name, color: "", background: "" };
            const matchedTemplate = (templates.data as TemplateInterface[]).find(t => t.background === edit.background);
            if (matchedTemplate) {
                nTag.background = matchedTemplate.background;
                nTag.color = matchedTemplate.color;
                availableCols.splice(0, 0, matchedTemplate);
            }
            setTag(nTag)
        }
        setTemplates(availableCols);
    }, [dispatch, edit]);

    useEffect(() => { getTemplates() }, [getTemplates]);

    const setTagName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value.replace(/[^ \w]/ig, '').trimStart();
        if(tag.name != e.target.value) {
            setIsNameEnabled(true);
        }  
        setTag({ ...tag, name });
        let isNameValid = name.length > 1 && name.length < 21 && /^[\w][\w ]*[\w]$/.test(name) && /[a-z][a-z]+/i.test(name)
        setValid(isNameValid);
    }

    const onCreate = async () => {
        const payload = { name: tag.name.trim(), template: { color: tag.color, background: tag.background } }
        const resp = await api(CONST.CHANNEL.TCM, CONST.METHOD.TCM.CREATELABEL, { body: payload }, dispatch, "Processing");
        if (resp.err) dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.err } });
        else {
            setTag({ name: "", color: "", background: "" });
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Label has been successfully created" } })
        }
        getTemplates();
    }

    const onEdit = async () => {
        if (edit) {
            const payload = { params: edit.name, body: { newName: tag.name.trim(), template: { color: tag.color, background: tag.background } } }
            const resp = await api(CONST.CHANNEL.TCM, CONST.METHOD.TCM.UPDATELABEL, payload, dispatch, "Processing");
            if (resp.err) dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.err } });
            else {
                setTag({ name: "", color: "", background: "" });
                dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Label has been successfully updated" } })
                if (editDone) editDone();
            }
            getTemplates();
        }
    }
    const setTagColor = (t:any) => {
        setTag ({ ...tag, ...t  }); 
        setIscolorEnabled(true);
    };

    return <div className="--create-label-container">
        <div className="main-card dds-link">
            <div className="--label-header">
                <span className="dds-h5-lm heading">{edit ? "Edit" : "Create"} label</span>
                {!edit && templates.length !== 0 && <span className="dds-link-sm">You can create upto 10 labels</span>}
            </div>
            {!edit && loaded && templates.length === 0 ?
                <div className="--label-body --max-limit">
                    <img className="--tag-icon" alt="tag-icon" src={TagGreyIconSvg}></img>
                    <span className="--label-item dds-link-sm">You have used up all the 10 labels.</span>
                    <span className="--label-item dds-link-sm">Please delete the labels you no longer require to create a new label</span>
                </div> :
                <div className="--label-body">
                    <div className="--label-item dds-body">
                        <label>Label name</label>
                        <input className={`--label-item-namebox ${valid ? '' : "invalid"}`} type="text" value={tag.name} onChange={setTagName} maxLength={20} minLength={2}></input>
                    </div>
                    <div className="--label-item dds-body">
                        <label>Label color</label>
                        <div className="--label-item-colorbox">
                            <Grid columns={6}>
                                {templates.map((t, i) =>
                                    <GridItem xs key={i}><div className={`--label-item-color ${tag.background === t.background ? "selected" : ""}`} onClick={() => { setTagColor(t) }}>
                                        <span style={{ backgroundColor: t.background }}></span>
                                    </div></GridItem>
                                )}
                            </Grid>
                        </div>
                    </div>

                </div>
            }
            <div className="--label-footer">
                <Button title={`${edit ? "Update" : "Create"} label`} className="--savebtn" onClick={() => (edit ? onEdit : onCreate)()} disabled={!isValid()} style={{ backgroundColor: isValid() ? CONST.COLOR.BASECOLOR : CONST.COLOR.BASECOLORDISABLED }} />
            </div>
        </div>
    </div>
};