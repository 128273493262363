
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../reducer";
import CONST from "../../locale.json"
import { CALL_NOTIFY } from "../../global/store/action"
import { api } from "../../api"
import { SELECTEDADMINSCREEN, SELECTEDROLE,SELECTEDLEAF ,SELECTEDPARENT} from "../store/action";
import { TableView } from "./TableView";
import { PopUpModel } from "../../global/components/PopUpModel";
const header =
    [{
        "name": "Role",
        "scope": "Scope"
    }];

export const RoleList = () => {
    const [roleList, setRoleList] = useState([]);
    const dispatch = useDispatch();
    const [deleteRoleObj, setDeleteRoleObj] = useState<{ roleState: boolean, roleName: string }>({ roleName: '', roleState: false });
    const navigate = useNavigate();
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);

    useEffect(() => {
        fetchRoles()
    }, [])

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'EDITROLE' })
        dispatch({type:SELECTEDPARENT, payload : 'User and Role Management'})  
    }, [])

    const editRole = (e: any) => {
        // passing roleName to fetch role details in update role
        navigate(devMode ? "/managerole" : "/admin/managerole")
        dispatch({ type: SELECTEDROLE, payload: e.name });
    }

    const deleteRole = async (name: string) => {
        // deleting roles
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.DELETEROLE, { params: name }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        else {
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Role has been successfully deleted." } });
            setDeleteRoleObj({ roleName: '', roleState: false })
        }
        fetchRoles();

    }

    const deletRoleConf = (name: string) => {
        setDeleteRoleObj({ roleName: name, roleState: true })
    }

    const fetchRoles = async () => {
        // fetch role api
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHROLE, {}, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        resp.data.forEach((item: any) => {
            for (let key in item) {
                if (!(Object.keys(header[0]).includes(key))) {
                    delete item[key]
                }
            }
        })
        setRoleList(resp.data)
    }
    return (
        <>
            <TableView Title={"Edit role"} searchPlaceHolder={"Search role"} tableData={roleList} showSLNo={true} header={Object.values(header[0])} onClickEdit={(e: any) => editRole(e)} onClickDelete={(e: any) => deletRoleConf(e.name)} filter="name" />
            {deleteRoleObj.roleState && <PopUpModel Title="Delete" classStyle={"--deleteBtn"} onClickClose={() => { setDeleteRoleObj({ roleName: '', roleState: false }) }} onSaveClick={() => { deleteRole(deleteRoleObj.roleName) }} screenBlock closeTitle="Cancel" saveTitle="Delete">
                <div className="body-content dds-body">Are you sure you want to delete the role?</div>
            </PopUpModel>}
        </>
    )

}