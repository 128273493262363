import { CSSProperties, FC, useEffect, useState } from "react"
import { SearchboxSvg } from ".."
import './SearchBox.scss'

interface SearchBoxInterface {
    className?: string;
    placeholder: string;
    onChange(newValue: string): void;
    value?: string;
    style?: CSSProperties;
}

export const SearchBox: FC<SearchBoxInterface> = ({ className = "", placeholder, style = {}, onChange, value = "" }) => {
    const [filterText, setFilterText] = useState<string>("");

    useEffect(() => {
        setFilterText(value)
    }, [value])

    useEffect(() => {
        if (onChange) onChange(filterText);
    }, [filterText, onChange])

    return (
        <div style={style} className={`searchbox-main ${className}`}>
            <img src={SearchboxSvg} alt="search-icon" />
            <input placeholder={placeholder} value={filterText} onChange={e => setFilterText(e.target.value)} />
        </div>
    )
}
