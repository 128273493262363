import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { EditStepSvg, Grid, GridItem, ToggleSwitch, TrashIconSvg } from "../../global";
import { CALL_NOTIFY } from "./../../global/store/action"
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import { api } from "../../api";
import { SSOConfig } from './SSOConfig';
import CONST from "../../locale.json";
import "./EditSSOConfig.scss"
import styled from '@emotion/styled';

export const EditSSOConfig = () => {
	const [configuration, setConfiguration] = useState<Record<"id" | "name" | "enabled", string | boolean>[]>([])
	const [samlActive, setSamlActive] = useState<boolean>(false)
	const [oidcActive, setOidcActive] = useState<boolean>(false)
	const [samlConfig, setSamlConfig] = useState<Record<string, any>>({})
	const [oidcConfig, setOidcConfig] = useState<Record<string, any>>({})
	const [oidcConfExist, setOidcConfExist] = useState(false)
	const [samlConfExist, setSamlConfExist] = useState(false)
	const [refresh, setRefresh] = useState(false)
	const [changed, setChanged] = useState<string>("")
	const [data, setData] = useState<Record<any, any>>({})
	const [configScreen, setConfigScreen] = useState({ show: false, data: {} })
	const effectRan = useRef(false)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch({ type: SELECTEDPARENT, payload: 'SSO configuration' })
		dispatch({ type: SELECTEDLEAF, payload: 'EDITSSOCONFIG' })
	}, [])

	const fetchConfig = async () => {
		const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHSSOCONFIG, {})
		if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while configuring SAML" } });
		if (resp.data.length > 0) {
			const data = resp.data[0]
			setData(resp.data[0])
			let conf = []
			if (data.saml) {
				setSamlConfExist(true)
				const newSamlConfig = { id: "saml", name: "SAML", enabled: data.saml.enabled }
				conf.push(newSamlConfig)
				setSamlConfig(data.saml)
				setSamlActive(data.saml.enabled)
			}
			if (data.oidc) {
				setOidcConfExist(true)
				const newOidcConfig = { id: "oidc", name: "OIDC", enabled: data.oidc.enabled, configured: true }
				conf.push(newOidcConfig)
				setOidcConfig(data.oidc)
				setOidcActive(data.oidc.enabled)
			}
			setConfiguration(conf)
		}
	}
	useEffect(() => {
		fetchConfig()
	}, [])
	useEffect(() => {
		(async () => {
			if (refresh) {
				if (changed === "saml") {
					let sConf = { ...samlConfig }
					sConf.enabled = samlActive
					await updateSSO(sConf, "saml")
					if (oidcConfExist && samlActive === true) {
						let oConfig = { ...oidcConfig }
						oConfig.enabled = !samlActive
						await updateSSO(oConfig, "oidc")
					}
				}
				if (changed === "oidc") {
					let oConfig = { ...oidcConfig }
					oConfig.enabled = oidcActive
					await updateSSO(oConfig, "oidc")
					if (samlConfExist && oidcActive === true) {
						let sConf = { ...samlConfig }
						sConf.enabled = !oidcActive
						await updateSSO(sConf, "saml")
					}
				}
				setConfiguration([])
				setSamlConfig({})
				setOidcConfig({})
				setOidcActive(false)
				setSamlActive(false)
				await fetchConfig()
				setRefresh(false)
			}
		})()

	}, [refresh])

	const updateSSO = async (ssoConfig: Record<string, string> | null, type: string) => {
		if (type === 'saml') {
			const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATESSOCONFIG, { body: { saml: ssoConfig }, params: "saml" }, dispatch)
			if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while configuring SAML" } });
		}
		else if (type === "oidc") {
			const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATESSOCONFIG, { body: { oidc: ssoConfig }, params: "oidc" }, dispatch)
			if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while configuring OIDC" } });
		}
	}
	const handleToggleChange = (confType: string) => {
		setChanged(confType)
		setRefresh(true)
	}
	const handleGB = () => {
		setConfigScreen({ show: false, data: {} })
	}
	const handleDelete = (confType: string) => {
		(async () => {
			if (confType === "saml") {
				let ssoConfig = null
				const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.DELETESSOCONFIG, { body: { saml: ssoConfig, id: data.id }, params: "saml" }, dispatch)
				if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while deleting SAML configuration" } });
				setSamlConfExist(false)
			}
			else if (confType === "oidc") {
				let ssoConfig = null
				const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.DELETESSOCONFIG, { body: { oidc: ssoConfig, id: data.id }, params: "oidc" }, dispatch)
				if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while deleting OIDC configuration" } });
				setOidcConfExist(false)
			}
			setConfiguration([])
			setSamlConfig({})
			setOidcConfig({})
			setOidcActive(false)
			setSamlActive(false)
			await fetchConfig()
			dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "SSO configuration has been deleted successfully" } });
		})()
	}
	return (configScreen.show ? <SSOConfig data={configScreen.data} goBack={handleGB} /> :
		<div className="--edit-label-container-sso">
			<div className="main-card dds-link">
				<div className="--notif-header">
					<span className="dds-h5-lm heading">SSO configuration</span>
				</div>
				{configuration?.length == 0 ?
					<div className="--notif-body --empty">
						<span className="--notif-item dds-link-sm">SSO not configured yet</span>
					</div> :
					<div className="--notif-body">
						<Grid style={{ gap: "12px", color: 'rgba(83, 86, 90, 1)' }} className="--Ntable-header dds-label-semibold">
							<GridItem xs className="--Ntable-sno">S.No.</GridItem>
							<GridItem className="--Ntable-col">Provider ID</GridItem>
							<GridItem className="--Ntable-col" xs={6.3}>Scope</GridItem>
							<GridItem xs className="--Ntable-edit" style={{ padding: '0px' }}>Edit</GridItem>
							<GridItem xs className="--Ntable-del" style={{ marginRight: '110px' }}>Delete</GridItem>
						</Grid>
						<div className="--Ntable-body">
							{configuration?.map((t: any, i: any) => {
								return (
									<Grid key={i} className="--Ntable-item dds-body">
										<GridItem xs className="--Ntable-sno">{i + 1}</GridItem>
										<GridItem className="--Ntable-col">{t.name}</GridItem>
										<GridItem xs={6.9} className="--Ntable-col">
											<ToggleSwitch handleToggle={handleToggleChange} id={t.id} active={t.id === "saml" ? samlActive : oidcActive} setActive={t.id === "saml" ? setSamlActive : setOidcActive} />
										</GridItem>
										<GridItem xs style={{ marginLeft: '12px' }} className={`--Ntable-edit}`} onClick={() => { setConfigScreen({ show: true, data: t.id === "saml" ? samlConfig : oidcConfig }) }}><img alt="Edit" src={EditStepSvg}></img></GridItem>
										<GridItem xs style={{ marginLeft: '4px' }} className={`--Ntable-del}`} onClick={() => { handleDelete(t.id) }}><img alt="Delete" src={TrashIconSvg}></img></GridItem>
									</Grid>
								)
							})}
						</div>
					</div>
				}
			</div>
		</div >
	)
}
