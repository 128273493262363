import { FC } from "react";
import "./ScreenBlock.scss"


export const ScreenBlock: FC = ({ children }) => {

    return (
        <>
            <div className="main-block-screen">
                {children}
            </div>
        </>

    )
}