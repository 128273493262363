import * as act from './action'
import { v4 as uuidv4 } from 'uuid';

interface StateInterface {
  notify: {
    id: string
    type: "ERROR" | "WARNING" | "SUCCESS" | "DEFAULT" | "INFO",
    msg: string
  }[],
  blockscreenMsg: string,
  refreshTreeData: boolean,
  showCoachMarks: boolean
  setDevMode: boolean
}

const initialState: StateInterface = {
  notify: [],
  blockscreenMsg: "",
  refreshTreeData: false,
  showCoachMarks: false,
  setDevMode: false,
}

type actionType = {
  type: string,
  payload: any
}

export default function globalReducer(state = initialState, action: actionType) {
  switch (action.type) {
    case act.REFRESH_TREEDATA:
      return { ...state, refreshTreeData: !state.refreshTreeData };
    case act.CALL_NOTIFY:
      const newObj = { ...action.payload, id: uuidv4() };
      return { ...state, notify: [...state.notify, newObj] }
    case act.CLOSE_NOTIFY:
      const leftNotify = state.notify.filter((ele) => ele.id !== action.payload)
      return { ...state, notify: leftNotify }
    case act.SHOWSCREENBLOCKMSG:
      return { ...state, blockscreenMsg: action.payload }
    case act.SHOWCOACHMARKS:
      return { ...state, showCoachMarks: action.payload }
    case act.SETDEVMODE:
      return { ...state, setDevMode: action.payload }
    default:
      return state
  }
}