import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchDropdown, AdminUserManagementIconSvg, AdminNotificationManagementIconSvg, AdminLicenseManagemetIconSvg, AdminProjectManangemtSvg, AdminLabelIcon, AdminThirdPartyInt, AdminSessionIconSvg, AdminConfig } from "../../global";
import { SELECTEDPARENT } from "../store/action";
import { CALL_NOTIFY} from "../../global/store/action"
import { Login } from "./Login";
import CONST from "../../locale.json";
import { RootState } from "../../reducer";
import "./AdminHome.scss";
import c from "config";
const menu: any = CONST.menu;

export const AdminHome = () => {
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState<string>("");
    const [options, setOptions] = useState<any>(menu);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [selectedTile, setSelectedTile] = useState<string>("")
    const [placeholder, setPlaceholder] = useState<string>("What do you want to do today?")
    const navigate = useNavigate()

    const getTargetIcon = useCallback((app: string) => ({
        userManagement: AdminUserManagementIconSvg,
        notificationManagement: AdminNotificationManagementIconSvg,
        licenseManagemet: AdminLicenseManagemetIconSvg,
        projectManagement: AdminProjectManangemtSvg,
        labelManagement: AdminLabelIcon,
        thirdParty: AdminThirdPartyInt,
        session: AdminSessionIconSvg,
        SSO: AdminConfig
    }[app]), []);

    const login = useSelector((state: RootState) => state.globalPersistReducer.login);

    const adminScreen = (e: any, screenName: string) => {

        navigate(screenName.toLowerCase())
        dispatch({ type: SELECTEDPARENT, payload: e.target.id });
    }

    const SelectedOption = (e: any) => {
        //Blocked for license management
        if (e.value === "CREATENEWLICENSE" || e.value === "LISTOFLICENSE") return
        CONST.tree.forEach((parent: any) => {
            parent.children.forEach((child: any) => {
                if (e.value === child.id) {
                    setSelectedTile(parent.id);
                    setOptions([]);
                    return
                }
            })
        })
        setSelectedOption(e.value);
        setPlaceholder("");
    }
    
    useEffect(() => {
        let filterTextArray = filterText.toLocaleLowerCase().replace(/[^a-zA-Z/ ]/g, "").split(" ");
        if(filterTextArray[0]!==""){
            setOptions(menu.filter((word: any) =>
            word.label.toLowerCase().includes(filterText.toLowerCase())));
        }else{
            setOptions([]);
        }
        
    }, [filterText])

    useEffect(()=>{
        if(localStorage.getItem('isloggedin')){
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Successfully logged in" } });
            localStorage.removeItem('isloggedin')
        }
    })

    return (
        <>
            {
                !login ?
                    <div className="admin-home-container">
                        <Login />
                    </div> :
                    <div className="admin-home-container">
                        <h2 className="header-text">Welcome Admin</h2>
                        <div className="admin-home-search">
                            <SearchDropdown className="--search-card" placeholder={placeholder} value={filterText} onChange={setFilterText} options={options} onclick={SelectedOption} />
                        </div>
                        <div className="admin-home-btns">
                            {CONST.tree.map((b: any) => (
                                //Blocked for license management
                                <div className={"menu-items dds-link" + ((b.id == selectedTile) ? " selected-tile" : "" + b.name === 'License management' ? " disable" : "")} key={b.name}>
                                    <div className="admin-action-icon">
                                        <h4  className={(b.name==='License management')?"dds-h4-mm disabled":"dds-h4-mm"}>{b.name}</h4>
                                    </div>
                                    <div className="admin-action" >
                                        <img className={(b.name==='License management')?"disabled":""} src={getTargetIcon(b.icon)}></img>

                                        <div>
                                            <div>
                                                {b.children.map((c: any) => {
                                                    if (c.name === "Create license" || c.name === "List of licenses") {
                                                        // If it's 'Create License' and there are licenses available, disable it
                                                        return (
                                                            <label
                                                                key={c.id}
                                                                id={b.id}
                                                                className="disabled"
                                                                style={{ cursor: 'not-allowed',color:"#a8d09e"}}

                                                            >
                                                                {c.name}
                                                            </label>
                                                        );
                                                    } else {
                                                        // For other options, apply the regular class and onClick handler
                                                        return (
                                                            <label
                                                                key={c.id}
                                                                id={b.id}
                                                                className={`${c.id !== selectedOption ? "dds-link label-link" : "dds-body-bold"}`}
                                                                onClick={(e) => {
                                                                    adminScreen(e, c.id);
                                                                }}
                                                            >
                                                                {c.name}
                                                            </label>
                                                        );
                                                    }
                                                })}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
            }
        </>
    )
}