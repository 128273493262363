import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../api";
import { EditStepSvg, Grid, GridItem, TrashIconSvg, SearchBox } from "../../global";
import { PopUpModel } from "../../global/components/PopUpModel";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import CONST from "../../locale.json";
import { NotificationConfig } from "./NotificationConfig";
import "./EditNotification.scss";

export const EditNotification = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [notificationUsers, setNotificationUsers] = useState<any>([]);
    const [data, setData] = useState<any>();
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [userDelObj, setUserDelObj] = useState<{ show: boolean, notifId: string }>({ notifId: '', show: false });
    const [filterText, setFilterText] = useState<string>('');

    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'Notification management' })
        dispatch({ type: SELECTEDLEAF, payload: 'EDITNOTIFICATION' })
    }, [])

    const getNotificationConfiguredUsers = useCallback(async () => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHNOTIFICATIONCONFIGUREDUSERS, { params: "fetchNotificationConfiguredUsers" }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        setLoaded(true)
        setNotificationUsers(resp.data)
    }, [dispatch])

    useEffect(() => { getNotificationConfiguredUsers() }, [getNotificationConfiguredUsers, selectedUser]);

    const onDelete = async (notifId: string) => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.DELETENOTIFICATIONCONFIG, { params: notifId }, dispatch, "Processing");
        if (resp.err) dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.err } });
        else {
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Notification deleted successfully" } })
            setUserDelObj({ notifId: '', show: false });
        }
        getNotificationConfiguredUsers();
    }
    const handleGB = () => {
        setSelectedUser(null)
    }
    useEffect(() => {
        setData(notificationUsers?.filter((p: Record<string, any>) => p["userName"]?.toLowerCase().includes(filterText?.toLowerCase()))
        )
    }, [filterText, notificationUsers])
    return selectedUser ? <NotificationConfig userSelected={selectedUser} handleGoBack={handleGB} /> : <div className="--edit-label-container-notification">
        <div className="main-card dds-link">
            <div className="--notif-header">
                <span className="dds-h5-lm heading">Edit notification </span>
                <SearchBox className="--search-card" value={filterText} placeholder={"Search"} onChange={setFilterText} />
            </div>
            {loaded && data?.length === 0 ?
                <div className="--notif-body --empty">
                    <span className="--notif-item dds-link-sm">No notifications configured yet</span>
                </div> :
                <div className="--notif-body">
                    <Grid className="--Ntable-header dds-label-semibold">
                        <GridItem xs style={{ textAlign: "center"}} className="--Ntable-sno">S.No.</GridItem>
                        <GridItem xs={4} className="--Ntable-col">Username</GridItem>
                        <GridItem xs={3} className="--Ntable-col">User email ID</GridItem>
                        <GridItem xs={1} style={{ display: "flex", justifyContent: "center" }}className="--Ntable-edit">Edit</GridItem>
                        <GridItem xs={1} style={{ display: "flex", justifyContent: "center" }} className="--Ntable-del">Delete</GridItem>
                    </Grid>
                    <div className="--Ntable-body">
                        {data?.map((t: any, i: any) => {
                            return (
                                <Grid key={i} className="--Ntable-item dds-body">
                                    <GridItem xs style={{ textAlign: "center"}} className="--Ntable-sno">{i + 1}</GridItem>
                                    <GridItem xs={4} className="--Ntable-col">{t.userName}</GridItem>
                                    <GridItem xs={3} className="--Ntable-col">{t.emailID}</GridItem>
                                    <GridItem xs={1.1} style={{ display: "flex", justifyContent: "center" }} className={`--Ntable-edit}`} onClick={() => { setSelectedUser(t) }}><img alt="Edit" src={EditStepSvg}></img></GridItem>
                                    <GridItem xs={1} style={{ display: "flex", justifyContent: "center" }} className={`--Ntable-del}`} onClick={() => { setUserDelObj({ notifId: t.id, show: true }) }}><img alt="Delete" src={TrashIconSvg}></img></GridItem>
                                </Grid>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
        {userDelObj.show && <PopUpModel Title="Delete" onClickClose={() => setUserDelObj({ notifId: '', show: false })} onSaveClick={() => onDelete(userDelObj.notifId)} screenBlock closeTitle="Cancel" saveTitle="Delete">
            <div className="body-content dds-body">Are you sure you want to delete this notification?</div>
        </PopUpModel>}
    </div>
};