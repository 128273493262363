import { FC } from "react";
import { ClickAwayListener } from "@mui/material";
import { AdminPointerIconSvg } from "../../global";
import './ToolTip.scss';
interface TooltipInterface {
    text: string,
    close?: () => void,
    left?: string,
}
export const ToolTip: FC<TooltipInterface> = ({ text, close = () => { }, left }) => {
    const closeTooltip = () => {
        close();
    }
    return (
        <ClickAwayListener onClickAway={closeTooltip}>
            <div className="tootip-name" style={{ marginLeft: left }}>
                <img src={AdminPointerIconSvg} onClick={() => console.log("hhh")} />
                <div style={{ marginLeft: left }} className="password-tooltip dds-label">{text}</div>
            </div>
        </ClickAwayListener>
    )
}