
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../global";
import CONST from "../../locale.json";
import { RootState } from "../../reducer";
import { api } from "../../api";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDLEAF, SELECTEDPARENT } from "../store/action";
import "./CreateRole.scss"

const featurs: string[] =
    [
        "Quick check",
        "Test author & debug",
        "Schedule execution",
        "Scan",
        "Normal execution",
        "Report",
        "Impact & risk analysis",
        // "Screen compare",
        "Data tables",
        "PDF compare",
        "Integrations"
    ];

export const CreateRole = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roleNameref = useRef<HTMLInputElement>(null);
    const [arr, setArr] = useState<any>([]);
    const [radioButton, setRadioButton] = useState(false);
    const [roleType, setRoleType] = useState<string>("");
    const [roleName, setRoleName] = useState<string>("");
    const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);

    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);

    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'User and Role Management' })
        dispatch({ type: SELECTEDLEAF, payload: "CREATEROLE" })

    }, [])
    useEffect(() => {
        // Enable or disable the button based on the form inputs
        setCreateButtonDisabled(!(roleName && roleType && (arr.length > 0 || !radioButton)));
    }, [roleName, roleType, arr, radioButton]);

    const createRole = async () => {

        if (roleNameref.current?.value === '') {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Role name is missing" } });
            return
        }

        if (roleType === '') {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please select role type" } });
            return
        }


        let roleBody = {
            "name": roleNameref.current?.value,
            "scan": arr.includes("Scan"),
            "quickCheck": arr.includes("Quick check"),
            "testAuthorAndDebug": arr.includes("Test author & debug"),
            "normalExecution": arr.includes("Normal execution"),
            "scheduledExecution": arr.includes("Schedule execution"),
            "reports": arr.includes("Report"),
            "impactAndRiskAnalysis": arr.includes("Impact & risk analysis"),
            //screenmatching microservice is removed
            /* "screenCompare": arr.includes("Screen compare"), */
            "datatables": arr.includes("Data tables"),
            "pdfCompare": arr.includes("PDF compare"),
            "integrations": arr.includes("Integrations"),
            "scope": roleType
        }

        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATEROLE, { body: roleBody }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Role has been successfully created" } });
        navigate(devMode ? "/editrole" : "/admin/editrole")
    }

    const selectAll = (e: any) => {
        if (e.target.id === 'full') {
            setRoleType("Full access")
            featurs.forEach((ele: any) => {
                setArr((prev: any) => [...prev, ele])
            })
            setRadioButton(false)
        }
        else {
            setRoleType("Custom defined")
            setArr([]);
            setRadioButton(true)
        }

    }
    const select = (e: any) => {
        //features[e.target.name] = !features[e.target.name]
        if (e.target.checked) {
            setArr((prev: any) => [...prev, e.target.name])
        } else {
            setArr((state: any) => state.filter((item: any) => item !== e.target.name))
        }
    }
    return (
        <div className="create-role-container dds-link">
            <h3 className="dds-h5-lm">Create role</h3>
            <div className="create-role-div">
                <label>Role type</label>
                <div className="role-txtbox">
                    <input className="admin-textbox" style={{ opacity: "0.6" }} readOnly value={"Custom role"} type="text" />
                </div>
            </div>
            <div className="create-role-div">
                <label>Role name</label>
                <div className="role-txtbox">
                    <input className="admin-textbox" ref={roleNameref} value={roleName} type="text" onChange={(e) => { setRoleName(e.target.value) }} />
                </div>
            </div>
            <div className="create-role-div">
                <label>Scope</label>
                <div className="Scope-radio">
                    <div>
                        <input type="radio" className="radio" name="scope" id="full" onChange={selectAll} />
                        <label style={{ width: "80%", marginLeft: '8px' }}>Full access</label>
                    </div>
                    <div>
                        <input className="radio" type="radio" name="scope" id="custom" onChange={selectAll} />
                        <label style={{ width: "80%", marginLeft: '8px' }} >Custom defined</label>
                    </div>
                </div>
            </div>

            <span>Authorise the scope of access associated with this role</span>
            <div className="create-role-featurs-div">
                <label>Features</label>
                <div className={radioButton ? "features-list" : "features-list disable"}>
                    {featurs.map((e) => (
                        <div className={arr.length === featurs.length ? "features disbale-check" : "features"}>
                            <label className="container">{e}
                                <input type="checkbox" checked={arr.includes(e)} onChange={(event) => { select(event) }} className="chkbox" name={e} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="create-user-btn">
                <Button title={"Create role"} className="--savebtn" onClick={createRole} disabled={createButtonDisabled} />
            </div>
        </div>
    )
}