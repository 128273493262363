import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CONST from "../../locale.json";
import { api } from "../../api"
import { TableView } from './TableView';
import { SELECTEDLEAF,SELECTEDPARENT } from "../store/action";
import { CALL_NOTIFY } from "../../global/store/action";
import { Buffer } from "buffer";
const header =
    [{
        "id": "id",
        "userName": "Username",
        "hostName": "Hostname",
        "ip": "IP address",
        "mac": "MAC address",
        "status": "Status",
        "updated": "Last status update"
    }];
const months = CONST.months;
export const ClientHealth = () => {

    const [clientHealth, setClientHealth] = useState<any>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type:SELECTEDPARENT, payload : 'Status and session'})
        dispatch({ type: SELECTEDLEAF, payload: 'CLIENTHEALTH' })
    }, [])
    const processDate = (d: string): string => {
        const date = new Date(TimeFormat(d))
        const dt = [date.getDate(), date.getMonth(), date.getFullYear()];
        return `${("0" + dt[0]).slice(-2)} ${months[+dt[1]]} ${dt[2]}`;
    };

    const processTime = (t: string): string => {
        const time = new Date(TimeFormat(t))
        const nt = [time.getHours(), time.getMinutes()];
        return `${("0" + ((+nt[0] % 12) || 12)).slice(-2)}:${("0" + nt[1]).slice(-2)} ${(+nt[0] < 12) ? "AM" : "PM"}`;
    };

    const TimeFormat = (time: any) => {
        var dateUTC = new Date(time);
        var dateIST = new Date(dateUTC.getTime());
        //date shifting for IST timezone (+5 hours and 30 minutes)
        dateIST.setHours(dateIST.getHours());
        dateIST.setMinutes(dateIST.getMinutes());
        return dateIST;
    }
    const getHealth = async () => {
        try {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CLIENTHEALTH, {}, dispatch);

            resp.data.forEach((item: any) => {
                for (let key in item) {
                    if (!(Object.keys(header[0]).includes(key))) {
                        delete item[key]
                    }
                }
            })
            resp.data.forEach((item: any) => {
                //item.lastAccess = `${new Date(item.lastAccess).toLocaleString()}`
                item.updated = (processDate(item.updated) + ", " + processTime(item.updated)).toString();
                if (item.status === "Dead") {
                    item.status = "Inactive"
                } else if (item.status === "Alive") {
                    item.status = "Active"
                }

            })
            resp.data.forEach((e: any) => {
                e.userName = Buffer.from(e.userName, 'base64').toString('utf-8');
            })
            setClientHealth(resp?.data)
        }
        catch (err) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Unable to connect to Microservice: Socket server" } });
        }
    }
    useEffect(() => {
        getHealth()
    }, [])

    return (
        <>
            <TableView Title={"Client health"} tableData={clientHealth} showSLNo={false} header={Object.values(header[0])} filter="userName" searchPlaceHolder="Search client" filterParams={["All","Active", "Inactive"]} />
        </>
    )

}