import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CONST from "../../locale.json";
import { CALL_NOTIFY } from "../../global/store/action";
import { api } from "../../api";
import { SELECTEDADMINSCREEN, SELECTEDPROJECT, SELECTEDLEAF, SELECTEDPARENT } from "../store/action";
import { RootState } from "../../reducer";
import { TableView } from './TableView';
const header =
    [{
        "id": "id",
        "name": "Project",
        "appType": "App type"
    }];
export const ProjectList = () => {
    // all project list
    const [projectList, setProjectList] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);

    const fetchProjects = async () => {
        // fetch all projects
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHPROJECT, { params: 'all' }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        resp.data.data.forEach((item: any) => {
            for (let key in item) {
                if (!(Object.keys(header[0]).includes(key))) {
                    delete item[key]
                }
            }
        })

        setProjectList(resp.data.data)
    }


    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'PROJECTLIST' })
        dispatch({ type: SELECTEDPARENT, payload: 'Project management' })
    }, [])

    useEffect(() => {
        fetchProjects()
    }, [])


    const editProject = (e: any) => {
        navigate(devMode ? "/editproject" : "/admin/editproject")
        dispatch({ type: SELECTEDPROJECT, payload: e });

    }

    return (
        <>
            <TableView Title={"Edit project"} tableData={projectList} showSLNo={true} header={Object.values(header[0])} onClickEdit={(e) => editProject(e)} filter="name" />
        </>
    )

}