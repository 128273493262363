export const REFRESH_TREEDATA = "REFRESH_TREEDATA";
export const CALL_NOTIFY = "CALL_NOTIFY";
export const CLOSE_NOTIFY = "CLOSE_NOTIFY";
export const CHANGE_SELECTEDPROJECT = "CHANGE_SELECTEDPROJECT";
export const UPDATE_PROJECTDATA = "UPDATE_PROJECTDATA";
export const SHOWSCREENBLOCKMSG = "SHOWSCREENBLOCKMSG";
export const CHANGE_SELECTEDLEAF = "CHANGE_SELECTEDLEAF";
export const SHOWCOACHMARKS = "SHOWCOACHMARKS";
export const USERINFO = "USERINFO";
export const LOGIN = "LOGIN";
export const SETDEVMODE = "SETDEVMODE"
