import { CSSProperties, FC, useEffect, useState } from "react"
import { ClickAwayListener } from "@mui/material";
import { SearchboxSvg } from "..";
import './SearchDropdown.scss';

interface SearchBoxInterface {
    className?: string;
    placeholder: string;
    onChange(newValue: string): void;
    value?: string;
    style?: CSSProperties;
    options?: any;
    onclick?(id: string): void;
    disabled?: boolean
}

export const SearchDropdown: FC<SearchBoxInterface> = ({ className = "", placeholder, style = {}, onChange, value = "", options, onclick, disabled }) => {
    const [filterText, setFilterText] = useState<string>("");
    const [showOption, setShowOption] = useState<boolean>(false);
    const [placeHolder, setPlaceHolder] = useState<string>(placeholder);
    const SelectOption = (p: any) => {
        if (onclick) {
            onclick(p);
            setFilterText(p.label)
        };
    }
    useEffect(() => {
        if (onChange) onChange(filterText);
    }, [filterText, onChange])
    const SelectedOption = (p: any) => {
        SelectOption(p);
        setFilterText("");
    }
    const onTextboxClick = (e: any, flag: boolean) => {
        if (flag) {
            setShowOption(true)
            setPlaceHolder("")
        }
        else {
            setShowOption(false);
            setPlaceHolder(placeholder);
        }
    }
    return (
        <div className="search-box">
            <div style={style} className={`searchbox-main ${className}`}>
                <img src={SearchboxSvg} alt="search-icon" />
                <ClickAwayListener onClickAway={(e) => onTextboxClick(e, false)}>
                    <input placeholder={placeHolder} value={filterText} onChange={e => setFilterText(e.target.value)} onClick={(e) => onTextboxClick(e, true)} disabled={disabled} />
                </ClickAwayListener>
            </div>
            {((options.length > 0) && showOption) ? <div className="options">
                {options?.map((p: any) => (
                    <div onClick={() => SelectedOption(p)} key={p.id}>
                        <label className="dds-body">{p.label}</label></div>
                ))}
            </div>
                : null}
        </div >
    )
}
