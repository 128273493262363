export const SELECTEDADMINSCREEN = "SELECTEDADMINSCREEN";
export const SELECTEDROLE = "SELECTEDROLE";
export const SELECTEDUSER = "SELECTEDUSER";
export const SELECTEDPROJECT = "SELECTEDPROJECT";
export const SELECTEDLEAF = "SELECTEDLEAF";
export const ACCESSTOKEN = "ACCESSTOKEN";
export const USERNAME = "USERNAME";
export const SELECTEDPARENT = "SELECTEDPARENT";
export const ADMINROLEID = "ADMINROLEID";
export const SET_ACTIVE_TAB = "SETACTIVETAB"
