import { useState, FC, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ChevronDownIconSvg, WarningScreenMsg, ChevronUpIconSvg } from "..";
import { RootState } from "../../reducer";
import { CHANGE_SELECTEDPROJECT, REFRESH_TREEDATA } from "../store/action";
import { SELECTEDADMINSCREEN, SELECTEDPARENT } from "../../admin/store/action";
import CONST from '../../locale.json'
import "./TreeViewer.scss";


/**
 * Tree component of the left panel populated by the treeData from global reducer
 *
 * @returns the tree component
 */

interface TreeNodeInterface {
    id: string;
    name: string;
    category: "parent" | "leaf";
    children?: TreeNodeInterface[];
};

interface TreeInterface {
    tree: Omit<TreeNodeInterface, "createdOn" | "modifiedOn">[];
};

type TreeProps = {
    data: TreeInterface["tree"];
    selectedParent: string[];
    onParentClick(id: string): void;
    selectedLeaf: string;
    selectedAdminScreen: string;
    onLeafClick(leaf: string, parent: string): void;
};

type TreeLeafProps = {
    data: TreeNodeInterface;
    onClick(e: any): void;
    selectedLeaf: string;
};

type TreeViewerProps = {
    onLeafClick?(i: any): void;
    noLeafActions?: boolean;
}

var tree: any = CONST.tree;

export const TreeViewer: FC<TreeViewerProps> = (props) => {

    const [leafClickData, setLeafClickData] = useState<{ leaf: TreeNodeInterface, pid: TreeNodeInterface | null } | undefined>(undefined)
    const [show, setShow] = useState(true);
    const selectedParentScreen = useSelector((state: RootState) => state.adminReducer.selectedParent);
    const [selectedParent, setSelectedParent] = useState<string[]>([]);
    //const [selectedParent, setSelectedParent] = useState<string[]>([]);
    const selectedAdmin = useSelector((state: RootState) => state.adminReducer.selectedAdminScreen);
    const sLeaf = useSelector((state: RootState) => state.adminReducer.selectedLeaf);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const onParentClick: TreeProps["onParentClick"] = (id) => {
        //Blocked for license management
        if (id === "License management") return
        const idIndex = selectedParent.indexOf(id);
        if (idIndex === -1) setSelectedParent(prev => [...prev, id]);
        else setSelectedParent(prev => { let c = [...prev]; c.splice(idIndex, 1); return c });
    };

    const onLeafClick = (leaf: string, parent: string) => {
        navigate(leaf.toLowerCase())
        dispatch({ type: REFRESH_TREEDATA, payload: parent })
        dispatch({ type: SELECTEDPARENT, payload: parent });
    };

    const clickLeafAction = (close: boolean = false) => {
        if (!close && leafClickData) {
            let { leaf, pid } = leafClickData
            if (pid != null) dispatch({ type: CHANGE_SELECTEDPROJECT, payload: pid.id })
            if (props.onLeafClick && props.onLeafClick instanceof Function) props.onLeafClick(leaf);
        }
        setLeafClickData(undefined)
    }
    useEffect(() => {
        setSelectedParent(selectedParentScreen);

    }, [selectedAdmin, selectedParentScreen])
    return (
        <>
            {(leafClickData) && <WarningScreenMsg onContinue={clickLeafAction} onClose={() => clickLeafAction(true)} />}
            <div className={"main-tree" + (show ? " main-tree-visible" : "") + (props.noLeafActions ? " no-leaf-actions" : "")} style={{ opacity: show ? 100 : 50, flexBasis: show ? 230 : 0, width: show ? "230px" : "0px" }}>
                <div className="--mainbox dds-link">
                    <Tree data={tree} selectedAdminScreen={selectedAdmin} selectedParent={selectedParent} onParentClick={onParentClick} selectedLeaf={sLeaf} onLeafClick={onLeafClick} />
                </div>
            </div>
            <div className="main-tree-handle" onClick={() => setShow(!show)}><img src={ChevronDownIconSvg} style={{ transform: `rotate(${show ? "90" : "-90"}deg)` }} alt="chevron" /></div>
        </>
    )
}

const TreeLeaf: FC<TreeLeafProps> = ({ data: leaf, onClick, selectedLeaf }) => {
    return (
        <div className={selectedLeaf === leaf.id ? "selected" : "leaf-container"}>
            <div className={`tree-node leaf-node`} onClick={onClick}>
                <span className="label" title={leaf.name}>{leaf.name}</span>
            </div>
        </div >
    )
};

const Tree: FC<TreeProps> = ({ data, onLeafClick, selectedAdminScreen, onParentClick, selectedParent, selectedLeaf }) => (
    <div className="tree-container">
        {data.map((project) => {
            const pExpanded = selectedParent.includes(project.id);
            return (
                <div key={project.id}>
                    <div className={`tree-node parent-node`} onClick={_e => { if (project.children && project.children.length > 0) onParentClick(project.id) }} >
                        <span className={(project.name === "License management")?"label disabled":"label"} >{project.name}</span>
                        <img  className={pExpanded ? "" :(project.name==='License management')?"rotate180 disabledIcon":"rotate180"} alt="expandBtn" src={ChevronUpIconSvg}></img>
                    </div>
                    <div className="tree-child">
                        {(pExpanded && project.children!.length > 0) ? project.children!.map((leaf) => (
                            <TreeLeaf key={leaf.id} data={leaf} selectedLeaf={selectedLeaf} onClick={_e =>
                                onLeafClick(leaf.id, project.id)
                            }
                            />
                        )) : null}
                    </div>
                </div>
            )
        })
        }
    </div>
);

